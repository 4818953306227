import { Suspense, lazy, useState } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';

import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
import CompanyGuard from '../guards/CompanyGuard';
import RoleBasedGuard from '../guards/RoleBasedGuard';
// components
import Spinner from '../components/Spinner';
// contexts

import { getCompanyId, getRoleId } from '../utilities/utilities';

const rolesArr = ['Admin', 'HR', 'Owner', 'Project Manager'];

const rolesPermission = ['Admin', 'HR', 'Owner', 'Project Manager', 'User', 'QA'];

// ----------------------------------------------------------------------
const { fetch: originalFetch } = window;
window.fetch = async (...args) => {
  const [resource, config] = args;

  const response = await originalFetch(resource, config);

  return response;
};
const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  const isDashboard = pathname.includes('/dashboard');

  return (
    <Suspense
      fallback={
        // <LoadingScreen
        <Spinner
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: 'fixed',
            }),
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  const [pendingApproval, setPendingApproval] = useState(0);

  return useRoutes([
    {
      path: '/',
      children: [
        {
          path: '/',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },

        {
          path: 'forget-password',
          element: (
            <GuestGuard>
              <ResetPassword />
            </GuestGuard>
          ),
        },
        {
          path: 'invitecompany',
          element: <InviteCompanyPage />,
        },
        {
          path: 'confirm-password',
          element: (
            <GuestGuard>
              <ConfirmPassword />
            </GuestGuard>
          ),
        },
        {
          path: 'create-password',
          element: (
            <GuestGuard>
              <VerifyCode />
            </GuestGuard>
          ),
        },
        {
          path: 'reset-password',
          element: (
            <GuestGuard>
              <VerifyCode />
            </GuestGuard>
          ),
        },
      ],
    },
    {
      path: '/register',
      element: (
        <GuestGuard>
          <Register />
        </GuestGuard>
      ),
    },

    // Company Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <CompanyGuard>
            <DashboardLayout pendingApproval={pendingApproval} setPendingApproval={setPendingApproval} />
          </CompanyGuard>
        </AuthGuard>
      ),
      children: [
        {
          path: 'app',
          element: (
            <RoleBasedGuard accessibleRoles={rolesPermission}>
              <GeneralApp />{' '}
            </RoleBasedGuard>
          ),
        },
        {
          path: 'attendance',
          children: [
            { path: 'view', element: <Attendance /> },
            {
              path: 'timing',
              element: (
                <RoleBasedGuard accessibleRoles={rolesPermission}>
                  <Timing />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'officedays',
              element: (
                <RoleBasedGuard accessibleRoles={rolesPermission}>
                  <UserWorkdays />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'officedays/view-officedays',
              element: (
                <RoleBasedGuard accessibleRoles={rolesPermission}>
                  <ViewUserWorkingDays />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'officedays/add-officedays',
              element: (
                <RoleBasedGuard accessibleRoles={rolesPermission}>
                  <AddUserWorkingDays />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'log',
              element: (
                <RoleBasedGuard accessibleRoles={rolesArr}>
                  <Log />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'breakview',
              element: (
                <RoleBasedGuard accessibleRoles={rolesArr}>
                  <BreakSection />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'breakreport',
              element: (
                <RoleBasedGuard accessibleRoles={rolesArr}>
                  <BreakReport />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'latesignin',
              element: (
                <RoleBasedGuard accessibleRoles={rolesPermission}>
                  <LateSignInApproval />
                </RoleBasedGuard>
              ),
              //  element: <LateSignInApproval />
            },
          ],
        },
        {
          path: 'leaves',
          children: [
            {
              path: 'dashboard',
              element: (
                <RoleBasedGuard accessibleRoles={rolesPermission}>
                  <Navigate to="dashboard/leaves/all" replace />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'all',
              element: (
                <RoleBasedGuard accessibleRoles={rolesPermission}>
                  <Leaves />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'all/view-leave',
              element: (
                <RoleBasedGuard accessibleRoles={rolesPermission}>
                  <ViewLeave />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'all/add-leave',
              element: (
                <RoleBasedGuard accessibleRoles={rolesPermission}>
                  <AddLeave />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'my-leaves',
              element: (
                <RoleBasedGuard accessibleRoles={rolesPermission}>
                  <UserLeaveRecords />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'my-leaves/view-leave',
              element: (
                <RoleBasedGuard accessibleRoles={rolesPermission}>
                  <ViewLeave />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'my-leaves/add-leave',
              element: (
                <RoleBasedGuard accessibleRoles={rolesPermission}>
                  <AddLeave />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'management',
              element: (
                <RoleBasedGuard accessibleRoles={rolesPermission}>
                  <LeaveRecords />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'management/view-entitlement',
              element: (
                <RoleBasedGuard accessibleRoles={rolesPermission}>
                  <ViewEntitlement />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'management/add-entitlement',
              element: (
                <RoleBasedGuard accessibleRoles={rolesPermission}>
                  <AddEntitlement />
                </RoleBasedGuard>
              ),
            },

            {
              path: 'pending-approvals',
              element: (
                // localStorage.getItem('showApproval') ?
                <RoleBasedGuard accessibleRoles={rolesPermission}>
                  <PendingApproval pendingApproval={pendingApproval} setPendingApproval={setPendingApproval} />
                </RoleBasedGuard>
              ),
              //  : <NotFound />
            },
            {
              path: 'pending-approvals/view-pending-approvals',
              element: (
                <RoleBasedGuard accessibleRoles={rolesPermission}>
                  <ViewLeave />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'holidays',
              element: (
                <RoleBasedGuard accessibleRoles={rolesPermission}>
                  <Holidays />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'holidays/add-holidays',
              element: (
                <RoleBasedGuard accessibleRoles={rolesPermission}>
                  <AddHolidays />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'holidays/view-holidays',
              element: (
                <RoleBasedGuard accessibleRoles={rolesPermission}>
                  <ViewHolidays />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'schedule',
              element: (
                <RoleBasedGuard accessibleRoles={rolesPermission}>
                  <LeaveSchedule />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'transactions',
              element: (
                <RoleBasedGuard accessibleRoles={rolesArr}>
                  <TransactionReport />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'entitlement',
              element: (
                <RoleBasedGuard accessibleRoles={rolesArr}>
                  <EntitlementReport />
                </RoleBasedGuard>
              ),
            },

            {
              path: 'types',
              element: (
                <RoleBasedGuard accessibleRoles={rolesArr}>
                  <LeaveTypes />
                </RoleBasedGuard>
              ),
            },

            {
              path: 'types/add-leavetypes',
              element: (
                <RoleBasedGuard accessibleRoles={rolesArr}>
                  <AddLeaveTypes />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'types/leavetypes',
              element: (
                <RoleBasedGuard accessibleRoles={rolesArr}>
                  <ViewLeaveTypes />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'workdays',
              element: (
                <RoleBasedGuard accessibleRoles={rolesArr}>
                  <WorkDays />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'workdays/add-workday',
              element: (
                <RoleBasedGuard accessibleRoles={rolesArr}>
                  <AddWorkDay />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'workdays/view-workday',
              element: (
                <RoleBasedGuard accessibleRoles={rolesArr}>
                  <ViewWorkDay />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'earning-policy',
              element: (
                <RoleBasedGuard accessibleRoles={rolesArr}>
                  <EarningPolicy />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'earning-policy/view-earning-policy',
              element: (
                <RoleBasedGuard accessibleRoles={rolesArr}>
                  <ViewEarningPolicy />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'earning-policy/add-earning-policy',
              element: (
                <RoleBasedGuard accessibleRoles={rolesArr}>
                  <AddEarningPolicy />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'approval-workflow',
              element: (
                <RoleBasedGuard accessibleRoles={rolesArr}>
                  <ApprovalWorkflow />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'approval-workflow/view-approval-workflow',
              element: (
                <RoleBasedGuard accessibleRoles={rolesArr}>
                  <ViewApprovalWorkflow />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'approval-workflow/add-approval-workflow',
              element: (
                <RoleBasedGuard accessibleRoles={rolesArr}>
                  <AddApprovalWorkflow />
                </RoleBasedGuard>
              ),
            },
          ],
        },

        {
          path: 'payroll',
          children: [
            {
              path: 'dashboard',
              element: (
                <RoleBasedGuard accessibleRoles={rolesPermission}>
                  <Navigate to="dashboard/payroll/salary-adjustment" replace />
                </RoleBasedGuard>
              ),
            },

            {
              path: 'salary-adjustment',
              element: (
                <RoleBasedGuard accessibleRoles={rolesPermission}>
                  <SalaryAdjustment />
                </RoleBasedGuard>
              ),
            },

            {
              path: 'salary-adjustment/new-salary-adjustment',
              element: (
                <RoleBasedGuard accessibleRoles={rolesPermission}>
                  <PayrollCreate />
                </RoleBasedGuard>
              ),
            },

            {
              path: 'salary-adjustment/view-salary-adjustment',
              element: (
                <RoleBasedGuard accessibleRoles={rolesPermission}>
                  <PayrollView />
                </RoleBasedGuard>
              ),
            },
          ],
        },

        {
          path: 'projects',
          children: [
            {
              path: '',
              element: (
                <Navigate to={`/dashboard/projects${localStorage.getItem('ProjectView') || '/cards'}`} replace />
              ),
            },
            { path: 'cards', element: <Projects /> },
            { path: 'list', element: <ProjectsList /> },
            {
              path: 'new',
              element: (
                <RoleBasedGuard accessibleRoles={rolesArr}>
                  <CreateProject />
                </RoleBasedGuard>
              ),
            },
            { path: 'view', element: <CreateProject /> },
            {
              path: 'edit',
              element: (
                <RoleBasedGuard accessibleRoles={rolesArr}>
                  <CreateProject />
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: 'tasks',
          children: [
            {
              path: '',
              element: <Navigate to={`/dashboard/tasks${localStorage.getItem('TaskView') || '/list'}`} replace />,
            },

            { path: 'list', element: <Tasks /> },
            { path: 'mytasklist', element: <MyTasksList /> },
            { path: 'new', element: <CreateTask /> },
            { path: 'view', element: <CreateTask /> },
            {
              path: 'edit',
              element: <CreateTask />,
            },
          ],
        },
        {
          path: 'kanban',
          children: [
            {
              path: '',
              element: <Navigate to={`dashboard/kanban${localStorage.getItem('kanbanView') || '/board'}`} replace />,
            },
            // { path: '/all', element: <Navigate to="/dashboard/tasks/list" replace /> },
            { path: 'board', element: <Kanban /> },
            { path: 'mytaskskanban', element: <MyTasksKanban /> },
            { path: 'new', element: <CreateTask /> },
            { path: 'view', element: <CreateTask /> },
            {
              path: 'edit',
              element: <CreateTask />,
            },
          ],
        },
        {
          path: 'timesheet',
          children: [
            {
              path: '',
              element: (
                <RoleBasedGuard accessibleRoles={rolesPermission}>
                  <Navigate to="dashboard/log/weekly" replace />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'weekly',
              element: (
                <RoleBasedGuard accessibleRoles={rolesPermission}>
                  <Weekly />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'view-weekly',
              element: (
                <RoleBasedGuard accessibleRoles={rolesPermission}>
                  <ViewWeekly />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'list',
              element: (
                <RoleBasedGuard accessibleRoles={rolesPermission}>
                  <ListView />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'missing-timesheet',
              element: (
                <RoleBasedGuard accessibleRoles={rolesPermission}>
                  <MissingList />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'missing-timesheet-user',
              element: (
                <RoleBasedGuard accessibleRoles={rolesPermission}>
                  <MissingListUser />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'calendar',
              element: (
                <RoleBasedGuard accessibleRoles={rolesPermission}>
                  <Calendar />
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: 'team',
          children: [
            {
              path: '',
              element: (
                <RoleBasedGuard accessibleRoles={rolesArr}>
                  <Navigate to="dashboard/team/all" replace />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'all',
              element: (
                <RoleBasedGuard accessibleRoles={rolesPermission}>
                  <TeamList />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'documents',
              element: (
                <RoleBasedGuard accessibleRoles={rolesPermission}>
                  <UserDocument />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'documents/view-user-document',
              element: (
                <RoleBasedGuard accessibleRoles={rolesPermission}>
                  <ViewUserDocument />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'all/new',
              element: (
                <RoleBasedGuard accessibleRoles={rolesArr}>
                  <TeamCreate />
                </RoleBasedGuard>
              ),
            },

            {
              path: 'all/edit',
              element: (
                <RoleBasedGuard accessibleRoles={rolesArr}>
                  <UserEdit key={document.location.href} />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'all/view',
              element: (
                <RoleBasedGuard accessibleRoles={rolesArr}>
                  <UserEdit key={document.location.href} />
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: 'assets',
          children: [
            {
              path: 'assettypes',
              element: (
                <RoleBasedGuard accessibleRoles={rolesArr}>
                  <CompanyAssets />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'assettypes/view-asset-type',
              element: (
                <RoleBasedGuard accessibleRoles={rolesArr}>
                  <ViewAssetType />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'assettypes/add-asset-type',
              element: (
                <RoleBasedGuard accessibleRoles={rolesArr}>
                  <AddAssetType />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'allassetslist',
              element: (
                <RoleBasedGuard accessibleRoles={rolesPermission}>
                  <AllAssetsList />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'allassetslist/view-asset',
              element: (
                <RoleBasedGuard accessibleRoles={rolesPermission}>
                  <ViewAsset />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'allassetslist/add-asset',
              element: (
                <RoleBasedGuard accessibleRoles={rolesPermission}>
                  <AddAsset />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'myassetslist',
              element: (
                <RoleBasedGuard accessibleRoles={rolesPermission}>
                  <MyAssetsList />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'myassetslist/view-my-asset',
              element: (
                <RoleBasedGuard accessibleRoles={rolesPermission}>
                  <MyAssetView />
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: 'user',
          children: [
            { path: '', element: <Navigate to="dashboard/user/view" replace /> },
            { path: 'view', element: <UserEdit key={document.location.href} /> },
            { path: 'edit', element: <UserEdit key={document.location.href} /> },
          ],
        },
        {
          path: 'account',
          children: [
            { path: '', element: <Navigate to="account/change-password" replace /> },
            { path: 'change-password', element: <ChangePassword /> },
          ],
        },
      ],
    },
    {
      path: 'admin',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        // Super Admin Routes
        { path: '', element: <Navigate to="admin/user-management" replace /> },
        { path: 'charts', element: <AdminDashboard /> },
        { path: 'superadminlist', element: <SuperAdminsList /> },
        { path: 'email-configuration', element: <EmailConfig /> },
        { path: 'email-templates', element: <EmailTemp /> },
        { path: 'role-management', element: <RoleManagement /> },
        { path: 'permission-management', element: <PermissionManagement /> },
        { path: 'user-management', element: <UserManagement /> },
        { path: 'company-management', element: <CompanyManagement /> },
        { path: 'owner-management', element: <OwnerManagement /> },
        { path: 'general-settings', element: <GeneralSettings /> },
      ],
    },

    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: '',
          element: (
            <Navigate
              to={
                Number(getRoleId()) === 10
                  ? '/admin/charts'
                  : getCompanyId()
                  ? '/dashboard/attendance/view'
                  : '/dashboard/company/view/edit'
              }
              replace
            />
          ),
        },
        {
          path: 'company',
          children: [
            {
              path: 'view',
              element: (
                <RoleBasedGuard accessibleRoles={rolesPermission}>
                  <CompanyView />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'view/edit',
              element: (
                <RoleBasedGuard accessibleRoles={rolesArr}>
                  <CompanyEdit />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'view/subcompany/:id',
              element: (
                <RoleBasedGuard accessibleRoles={rolesArr}>
                  <SubCompanyView />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'view/subcompany',
              element: (
                <RoleBasedGuard accessibleRoles={rolesArr}>
                  <SubCompanyView />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'documents',
              element: (
                <RoleBasedGuard accessibleRoles={rolesArr}>
                  <CompanyUserDocuments />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'documents/view-document',
              element: (
                <RoleBasedGuard accessibleRoles={rolesPermission}>
                  <ViewDocuments />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'documents/add-document',
              element: (
                <RoleBasedGuard accessibleRoles={rolesPermission}>
                  <AddDocuments />
                </RoleBasedGuard>
              ),
            },
            { path: 'new', element: <CreateCompanyDocument /> },
            { path: 'documentView', element: <CreateCompanyDocument /> },
            {
              path: 'documentEdit',
              element: <CreateCompanyDocument />,
            },
            // {
            //   path: 'documents',
            //   children: [
            //     {
            //       path: '',
            //       element: <Navigate to={`/dashboard/company/documents`} replace />,
            //     },

            //     { path: 'documents', element: <CompanyUserDocuments /> },
            //     { path: 'new', element: <CreateCompanyDocument /> },
            //     { path: 'view', element: <CreateCompanyDocument /> },
            //     {
            //       path: 'documentEdit',
            //       element: <CreateCompanyDocument />,
            //     },
            //   ],
            // },
          ],
        },
      ],
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'email-sent', element: <EmailSent /> },

        { path: '500', element: <Page500 /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },

    { path: '*', element: <Navigate to="/404" replace /> },
    { path: 'loading', element: <LoadingPage /> },
  ]);
}

// IMPORT COMPONENTS

// Authentication
const Login = Loadable(lazy(() => import('../pages/authentication/Login')));
const Register = Loadable(lazy(() => import('../pages/authentication/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/authentication/ResetPassword')));
const ConfirmPassword = Loadable(lazy(() => import('../pages/authentication/ConfirmPassword')));
const ChangePassword = Loadable(lazy(() => import('../pages/account/AccountPassword')));
const VerifyCode = Loadable(lazy(() => import('../pages/authentication/VerifyCode')));
// Dashboard
const GeneralApp = Loadable(lazy(() => import('../pages/dashboard/DashboardApp')));
const Attendance = Loadable(lazy(() => import('../pages/dashboard/Attendance')));
const Leaves = Loadable(lazy(() => import('../pages/dashboard/leaves/Leaves')));
const ViewLeave = Loadable(lazy(() => import('../pages/dashboard/leaves/ViewLeave')));
const AddLeave = Loadable(lazy(() => import('../pages/dashboard/leaves/AddLeave')));
const ViewEntitlement = Loadable(lazy(() => import('../pages/dashboard/leaves/ViewEntitlement')));
const AddEntitlement = Loadable(lazy(() => import('../pages/dashboard/leaves/AddEntitlement')));
const LeaveRecords = Loadable(lazy(() => import('../pages/dashboard/leaves/EntitlmentRecords')));
const EntitlementReport = Loadable(lazy(() => import('../pages/dashboard/leaves/EntitlementReport')));
const TransactionReport = Loadable(lazy(() => import('../pages/dashboard/leaves/TransactionReport')));
const EarningPolicy = Loadable(lazy(() => import('../pages/dashboard/leaves/EarningPolicy')));
const ViewEarningPolicy = Loadable(lazy(() => import('../pages/dashboard/leaves/ViewEarningPolicy')));
const AddEarningPolicy = Loadable(lazy(() => import('../pages/dashboard/leaves/AddEarningPolicy')));
const LeaveTypes = Loadable(lazy(() => import('../pages/dashboard/leaves/LeaveTypes')));
const WorkDays = Loadable(lazy(() => import('../pages/dashboard/leaves/WorkDays')));
const AddWorkDay = Loadable(lazy(() => import('../pages/dashboard/leaves/AddWorkDay')));
const ViewWorkDay = Loadable(lazy(() => import('../pages/dashboard/leaves/ViewEditWorkDay')));
const AddLeaveTypes = Loadable(lazy(() => import('../pages/dashboard/leaves/AddLeaveTypes')));
const ViewLeaveTypes = Loadable(lazy(() => import('../pages/dashboard/leaves/ViewLeaveTypes')));
const PendingApproval = Loadable(lazy(() => import('../pages/dashboard/leaves/PendingApprovals')));
const Holidays = Loadable(lazy(() => import('../pages/dashboard/leaves/Holidays')));
const AddHolidays = Loadable(lazy(() => import('../pages/dashboard/leaves/AddHolidays')));
const ViewHolidays = Loadable(lazy(() => import('../pages/dashboard/leaves/ViewHolidays')));
const ApprovalWorkflow = Loadable(lazy(() => import('../pages/dashboard/leaves/ApprovalWorkflow')));
const ViewApprovalWorkflow = Loadable(lazy(() => import('../pages/dashboard/leaves/ViewApprovalWorkflow')));
const AddApprovalWorkflow = Loadable(lazy(() => import('../pages/dashboard/leaves/AddApprovalWorkflow')));
const LeaveSchedule = Loadable(lazy(() => import('../pages/dashboard/leaves/LeaveSchedule')));
const UserLeaveRecords = Loadable(lazy(() => import('../pages/dashboard/leaves/UserLeaveRecords')));
const SalaryAdjustment = Loadable(lazy(() => import('../pages/dashboard/payroll/SalaryAdjustment')));
const PayrollCreate = Loadable(lazy(() => import('../pages/dashboard/PayrollCreate')));
const PayrollView = Loadable(lazy(() => import('../pages/dashboard/PayrollView')));

const Timing = Loadable(lazy(() => import('../pages/dashboard/attendance/Timing')));
const Log = Loadable(lazy(() => import('../pages/dashboard/attendance/AttendanceLog')));
const BreakSection = Loadable(lazy(() => import('../pages/dashboard/attendance/BreakSection')));
const BreakReport = Loadable(lazy(() => import('../pages/dashboard/attendance/BreakReport')));
const UserWorkdays = Loadable(lazy(() => import('../pages/dashboard/attendance/UserWorkingDays')));
const ViewUserWorkingDays = Loadable(lazy(() => import('../pages/dashboard/attendance/ViewUserWorkingDays')));
const AddUserWorkingDays = Loadable(lazy(() => import('../pages/dashboard/attendance/AddUserWorkingDays')));
const LateSignInApproval = Loadable(lazy(() => import('../pages/dashboard/attendance/LateSignInApproval')));
const CompanyView = Loadable(lazy(() => import('../pages/dashboard/CompanyView')));
const CompanyEdit = Loadable(lazy(() => import('../pages/dashboard/CompanyEdit')));
const SubCompanyView = Loadable(lazy(() => import('../pages/dashboard/SubCompanyView')));
const CompanyUserDocuments = Loadable(lazy(() => import('../pages/dashboard/CompanyUserDocuments')));
const ViewDocuments = Loadable(lazy(() => import('../pages/dashboard/documents/ViewDocuments')));
const AddDocuments = Loadable(lazy(() => import('../pages/dashboard/documents/AddDocuments')));
const Projects = Loadable(lazy(() => import('../pages/dashboard/Projects')));
const Tasks = Loadable(lazy(() => import('../pages/dashboard/Tasks')));
const MyTasksList = Loadable(lazy(() => import('../pages/dashboard/MyTasksList')));
const Weekly = Loadable(lazy(() => import('../pages/dashboard/Timesheet')));
const ViewWeekly = Loadable(lazy(() => import('../pages/dashboard/ViewWeekly')));
const ListView = Loadable(lazy(() => import('../pages/dashboard/ListView')));
const MissingList = Loadable(lazy(() => import('../pages/dashboard/MissingList')));
const MissingListUser = Loadable(lazy(() => import('../pages/dashboard/MissingListUser')));
const TeamList = Loadable(lazy(() => import('../pages/dashboard/TeamList')));
const UserDocument = Loadable(lazy(() => import('../pages/dashboard/UserDocument')));
const ViewUserDocument = Loadable(lazy(() => import('../pages/dashboard/documents/ViewUserDocument')));
const UserEdit = Loadable(lazy(() => import('../pages/dashboard/UserEdit')));
const TeamCreate = Loadable(lazy(() => import('../pages/dashboard/TeamCreate')));
const Calendar = Loadable(lazy(() => import('../pages/dashboard/Calendar')));
const Kanban = Loadable(lazy(() => import('../pages/dashboard/Kanban')));
const MyTasksKanban = Loadable(lazy(() => import('../pages/dashboard/MyTasksKanban')));
const CreateProject = Loadable(lazy(() => import('../pages/dashboard/CreateProject')));
const CreateTask = Loadable(lazy(() => import('../pages/dashboard/CreateTask')));
const ProjectsList = Loadable(lazy(() => import('../pages/dashboard/ProjectsList')));
const CompanyAssets = Loadable(lazy(() => import('../pages/dashboard/CompanyAssets')));
const ViewAssetType = Loadable(lazy(() => import('../pages/dashboard/assets/ViewAssetType')));
const AddAssetType = Loadable(lazy(() => import('../pages/dashboard/assets/AddAssetType')));
const AllAssetsList = Loadable(lazy(() => import('../pages/dashboard/AllAssetsList')));
const ViewAsset = Loadable(lazy(() => import('../pages/dashboard/assets/ViewAsset')));
const AddAsset = Loadable(lazy(() => import('../pages/dashboard/assets/AddAsset')));
const MyAssetsList = Loadable(lazy(() => import('../pages/dashboard/MyAssetsList')));
const CreateCompanyDocument = Loadable(lazy(() => import('../pages/dashboard/CreateCompanyDocument')));
const MyAssetView = Loadable(lazy(() => import('../pages/dashboard/assets/MyAssetView')));
// Super Admin
const AdminDashboard = Loadable(lazy(() => import('../pages/superAdmin/DashboardApp')));
const EmailConfig = Loadable(lazy(() => import('../pages/superAdmin/EmailConfig')));
const EmailTemp = Loadable(lazy(() => import('../pages/superAdmin/EmailTemplates')));
const RoleManagement = Loadable(lazy(() => import('../pages/superAdmin/RoleManagement')));
const PermissionManagement = Loadable(lazy(() => import('../pages/superAdmin/PermissionManagement')));
const UserManagement = Loadable(lazy(() => import('../pages/superAdmin/UserManagement')));
const CompanyManagement = Loadable(lazy(() => import('../pages/superAdmin/CompanyManagement')));
const OwnerManagement = Loadable(lazy(() => import('../pages/superAdmin/OwnerManagement')));
const GeneralSettings = Loadable(lazy(() => import('../pages/superAdmin/GeneralSettings')));
const SuperAdminsList = Loadable(lazy(() => import('../pages/superAdmin/SuperAdminsList')));
// Main

const EmailSent = Loadable(lazy(() => import('../pages/EmailSent')));

const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
// Components
const InviteCompanyPage = Loadable(lazy(() => import('../pages/account/InviteCompanyPage')));
//
const LoadingPage = Loadable(lazy(() => import('../pages/dashboard/LoadingPage')));
