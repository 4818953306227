import { createStore } from 'zustand/vanilla';

const changedStore = (set) => ({
  isChanged: false,
  setIsChanged: (input) => set(() => ({ isChanged: input })),
});

const useChangedStore = createStore(changedStore);

export default useChangedStore;
