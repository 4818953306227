import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
// store
// import { propertyStore } from '../stores/property';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
// eslint-disable-next-line import/no-unresolved
// import { AuthContext } from 'src/contexts/JWTContext';
// import { useContext } from 'react';
import { useZustand } from 'use-zustand';
import useChangedStore from '../hooks/useZustand';
// ----------------------------------------------------------------------

RouterModal.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  nextPath: PropTypes.string,
};

export default function RouterModal({ open, setOpen, nextPath }) {
  const navigate = useNavigate();
  const setIsChanged = useZustand(useChangedStore, (state) => state.setIsChanged);

  const handleTrigger = () => {
    setOpen(false);
    setIsChanged(false);
    navigate(nextPath);
  };

  return (
    <DialogComponent
      open={open}
      setOpen={setOpen}
      handleTrigger={handleTrigger}
      title="Move away?"
      content="You still have unsaved form data which will be lost if you proceed."
      ctaText="I'm sure"
    />
  );
}

DialogComponent.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  handleTrigger: PropTypes.func,
  title: PropTypes.string,
  content: PropTypes.string,
  ctaText: PropTypes.string,
};
// eslint-disable-next-line no-unused-vars
function DialogComponent({ open, setOpen, handleTrigger, title, content, ctaText }) {
  const handleClose = () => setOpen(false);
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{content}</DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleClose}>
          No
        </Button>
        <Button variant="contained" onClick={handleTrigger}>
          {`Confirm`}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
